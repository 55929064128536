
.card-product{
    margin-top: 30px;

    .price-container{
        margin-top: 9px;
    }

    .btn{
        margin: 0;
    }

    .image{
        overflow: hidden;
        border-radius: $border-radius-large;
    }

    .card-footer{
        margin-top: 5px;
        .price{
            h4{
                margin-bottom: 0;
                margin-top: 0;
            }
        }
        .stats{
            margin-top: 5px;

            .btn{
                position: relative;
                top: -3px;
            }
        }
    }

    .card-title,
    .category,
    .card-description{
        text-align: center;
    }
    .category{
        margin-bottom: 0;
    }
    .category ~ .card-title{
        margin-top: 0;
    }

    .price{
        font-size: 16px;
        color: $default-color;
    }
    .price-old{
        text-decoration: line-through;
        font-size: 16px;
        color: $default-color;
    }

    .price-new{
        color: $danger-color;
    }

    &:not(.card-plain) .card-image{
        padding: 15px;
    }
}
