.blockquote{
    &.blockquote-info{
        border-color: $info-color;
        color: $info-color;

        small{
            color: $info-color;
        }
    }
}
