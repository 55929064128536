.modal{
  .modal-login{
      max-width: 320px;

      .card-login{
          .logo-container{
              width: 65px;
              margin-bottom: 38px;
              margin-top: 27px;
          }
      }
  }
}
