[class*="blogs-"]{
    padding: 50px 0;
}


.blogs-1{
    .card{
        margin-bottom: 80px;
    }
}

.blogs-4{
    .card{
        margin-bottom: 60px;
        text-align: center;
    }
}
